@import '../../shared/vars';

.psv-container {
    --psv-markers-plugin-loaded: true;
}

.psv-markers {
    user-select: none;
    position: absolute;
    z-index: $psv-hud-zindex;
    width: 100%;
    height: 100%;

    &-svg-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $psv-polygon-marker-zindex;
    }
}

.psv-marker {
    display: none;

    &--normal {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $psv-marker-zindex;
        overflow: visible;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &--transparent {
        display: block;
        opacity: 0;
    }

    &--visible {
        display: block;
    }

    &--has-tooltip,
    &--has-content {
        cursor: pointer;
    }
}
